import { Box, Flex } from 'grid-styled'
import * as React from 'react'
import Layout from '../components/Layout'
import { Helmet } from 'react-helmet'

import CTA from '../components/CTA'
import Header from '../components/Header'
import { FAQContainer, FAQs } from '../components/FAQs'
import { AboutContact } from '.'

export default () => (
  <Layout>
    <Helmet>
      <title>Privacy Policy - Queensland Sports Aviators</title>
      <meta
        name="description"
        content="Here at Queensland Sports Aviators, your privacy and online security is important to us. For your full knowledge, please read our privacy policy to determine how we gather and use information."
      />
    </Helmet>
    <Header>
      <h1>Privacy Policy</h1>
    </Header>
    <FAQContainer>
      <FAQs>
        <div>
          <h4>Privacy Policy</h4>
          <p>
            <span>
              When you trust us with your personal information, you expect us to
              protect it and keep it safe.
            </span>
          </p>
          <p>
            <span>
              We are bound by the Privacy Act 1988 (Cth) (&lsquo;Privacy
              Act&rsquo;) and will protect your personal information in
              accordance with the Australian Privacy Principles. These
              principles govern how we can collect, use, hold and disclose your
              personal information, as well as ensuring the quality and security
              of your personal information.
            </span>
          </p>
          <p>
            <span>
              If you would like more information about how we protect your
              privacy, please contact us.
            </span>
          </p>
          <h4>About this policy</h4>
          <p>
            <span>
              This privacy policy explains how we manage your personal
              information. We may provide more details on how we manage your
              personal information when we collect your personal information.
            </span>
          </p>
          <h4>
            <span>What is personal information?</span>
          </h4>
          <p>
            <span>
              Personal information includes any information or opinion, about an
              identified individual or an individual who can be reasonably
              identified from their information. The information or opinion will
              still be personal information whether it is true or not and
              regardless of whether we have kept a record of it.
            </span>
          </p>
          <p>
            <span>
              The information that we seek to collect about you will depend on
              the products or services that we provide. If you do not allow us
              to collect all of the information we request, we may not be able
              to deliver all of those services effectively.
            </span>
          </p>
          <h4>
            <span>
              What kinds of personal information do we collect and hold?
            </span>
          </h4>
          <p>
            <span>
              When you apply for our products or services we may ask for
              identification information. This could include your name, address,
              contact details and date of birth. We may also collect your tax
              file number if we are authorised to collect it and if you choose
              to supply it. If you apply for insurance, we may collect
              information about what is being insured, the beneficiaries, and
              your health and financial situation, depending on the type of
              insurance.
            </span>
          </p>
          <p>
            <span>
              Throughout the life of your product or service, we may collect and
              hold additional personal information about you. This could include
              transaction information or making a record of queries or
              complaints you make and, if you make an insurance claim,
              collecting additional information to assess the claim.
            </span>
          </p>
          <p>
            <span>
              The collection of sensitive information is restricted by the
              Privacy Act. This includes information about your religion, racial
              or ethnic origin, political opinions, criminal record, and sexual
              orientation. It also includes health information and biometric
              information.
            </span>
          </p>
          <p>
            <span>
              Generally, we only collect this sort of information if it is
              necessary to provide you with a specific product or service and
              you have consented to that collection. For example, we may collect
              health information about you to process a claim under an insurance
              policy or collect voice biometric information to verify your
              identity or authorise transactions.
            </span>
          </p>
          <h4>
            <span>
              For what purposes do we collect, hold, use and disclose personal
              information?
            </span>
          </h4>
          <p>
            <span>
              The main reason we collect, use, hold and disclose personal
              information is to provide you with products and services. This
              includes:
              <br />
              &bull; checking whether you are eligible for the product or
              service;
              <br />
              &bull; assisting you where online applications are not completed;
              <br />
              &bull; providing the product or service; and
              <br />
              &bull; helping manage the product or service.
            </span>
          </p>
          <p>
            <span>
              We may also use your information to comply with legislative or
              regulatory requirements in any jurisdiction, prevent fraud, crime
              or other activity that may cause harm in relation to our products
              or services and to help us run our business. We may also use your
              information to tell you about products or services we think may
              interest you.
            </span>
          </p>
          <h4>
            <span>How do we collect personal information?</span>
          </h4>
          <p>
            <span>
              We collect most personal information directly from you. For
              example, we will collect your personal information when you apply
              for or use a product or service or talk to us in person or on the
              phone.
            </span>
          </p>
          <p>
            <span>
              We also collect information from you electronically. For instance,
              when you visit our website or if you send us electronic
              correspondence (see &ldquo;Do we collect personal information
              electronically?&rdquo;).
            </span>
          </p>
          <p>
            <span>
              Sometimes we collect personal information about you from other
              people or organisations. This may happen without your direct
              involvement. For instance, we may collect personal information
              about you from:
              <br />
              &bull; publicly available sources of information, such as public
              registers;
              <br />
              &bull; your representatives (including your legal adviser,
              mortgage broker, executor, administrator, guardian, trustee, or
              attorney);
              <br />
              &bull; your employer;
              <br />
              &bull; other organisations, who jointly with us, provide products
              or services to you;
              <br />
              &bull; commercial information service providers, such as companies
              that provide fraud prevention reports; and
              <br />
              &bull; insurers, re-insurers and health care providers.
            </span>
          </p>
          <h4>
            <span>
              What laws require or authorise us to collect personal information?
            </span>
          </h4>
          <p>
            <span>
              We are required or authorised to collect:
              <br />
              &bull; certain identification information about you by the
              Anti-Money Laundering and Counter-Terrorism Financing Act 2006
              (Cth) and Anti-Money Laundering and Counter-Terrorism Financing
              Rules Instrument 2007 (No. 1);
              <br />
              &bull; your Tax File Number, if you choose to provide it, by the
              Income Tax Assessment Act 1936 (Cth); and
              <br />
              &bull; certain information in relation to your application if you
              have applied for an insurance as required by the Insurance
              Contracts Act 1984 (Cth).
            </span>
          </p>
          <h4>
            <span>How do we hold personal information?</span>
          </h4>
          <p>
            <span>
              Much of the information we hold about you will be stored
              electronically in secure data centres which are located in and
              owned by either or external service providers. Some information we
              hold about you will be stored in paper files. We use a range of
              physical and electronic security measures to protect the security
              of the personal information we hold. For example:
              <br />
              &bull; access to information systems is controlled through
              identity and access management;
              <br />
              &bull; employees are bound by internal information security
              policies and are required to keep information secure;
              <br />
              &bull; all employees are required to complete training about
              information security; and
              <br />
              &bull; we regularly monitor and review our compliance with
              internal policies and industry best practice.
              <br />
              We take reasonable steps to destroy or permanently de-identify any
              personal information after it can no longer be used.
            </span>
          </p>
          <h4>
            <span>
              Who do we disclose your personal information to, and why?
            </span>
          </h4>
          <p>
            <span>
              We may provide personal information about our clients to
              organisations outside Parinity. To protect personal information,
              we enter into contracts with our service providers that require
              them to comply with the Privacy Act. These contracts oblige them
              to only use the personal information we disclose to them for the
              specific role we ask them to perform.
            </span>
          </p>
          <p>
            <span>
              Generally, we disclose personal information to organisations that
              help us with our business. These may include:
              <br />
              &bull; our agents, contractors and external service providers (for
              example, mailing houses and technology service providers);
              <br />
              &bull; paraplanning service providers;
              <br />
              &bull; insurers, re-insurers and health care providers;
              <br />
              &bull; payment systems operators (for example, merchants receiving
              card payments);
              <br />
              &bull; other organisations, who jointly with us, provide products
              or services to you;
              <br />
              &bull; financial services organisations, including banks,
              superannuation funds, stockbrokers, custodians, fund managers and
              portfolio service providers;
              <br />
              &bull; debt collectors;
              <br />
              &bull; our, legal advisers or auditors;
              <br />
              &bull; your representatives (including your legal adviser,
              accountant, mortgage broker, , executor, administrator, guardian,
              trustee, or attorney);
              <br />
              &bull; fraud bureaus or other organisations to identify,
              investigate or prevent fraud or other misconduct;
              <br />
              &bull; IT Service Providers;
              <br />
              &bull; Our Licensee, The Parinity Group Pty Ltd (AFSL 458943), and
              their related entities;
              <br />
              &bull; external dispute resolution schemes; and
              <br />
              &bull; regulatory bodies, government agencies and law enforcement
              bodies in any jurisdiction.
            </span>
          </p>
          <p>
            <span>
              We may also disclose your personal information to others outside
              Painity where:
              <br />
              &bull; we are required or authorised by law or where we have a
              public duty to do so;
              <br />
              &bull; you may have expressly consented to the disclosure or the
              consent may be reasonably inferred from the circumstances; or
              <br />
              &bull; we are otherwise permitted to disclose the information
              under the Privacy Act.
            </span>
          </p>
          <h4>
            <span>Do we disclose personal information overseas?</span>
          </h4>
          <p>
            <span>
              We may disclose your personal information to a recipient which is
              located outside Australia. This includes:
              <br />
              &bull; Parinity&rsquo;s service providers which are likely to be
              located in Thailand;
              <br />
              &bull; Any financial institution which you hold an account with
              overseas where you have given us permission to make enquiries on
              your behalf.
            </span>
          </p>
          <h4>
            <span>
              Do we use or disclose personal information for marketing?
            </span>
          </h4>
          <p>
            <span>
              We will use your personal information to offer you products and
              services we believe may interest you, but we will not do so if you
              tell us not to. We may offer you products and services by various
              means, including by mail, telephone, email, SMS or other
              electronic means , such as through social media or targeted
              advertising through Parinity websites.
            </span>
          </p>
          <p>
            <span>
              We may also disclose your personal information to companies
              outside Parinity who assist us to market our products and services
              to you.
            </span>
          </p>
          <p>
            <span>
              If you don&rsquo;t want to receive marketing offers from us please
              contact us.
            </span>
          </p>
          <h4>
            <span>Do we collect personal information electronically?</span>
          </h4>
          <p>
            <span>
              We will collect information from you electronically, for instance
              through internet browsing, mobile or tablet applications.
            </span>
          </p>
          <p>
            <span>
              Each time you visit our website, we collect information about your
              use of the website, which may include the following:
              <br />
              &bull; The date and time of visits;
              <br />
              &bull; Which pages are viewed;
              <br />
              &bull; How users navigate through the site and interact with pages
              (including fields completed in forms and applications completed);
              <br />
              &bull; Location information about users;
              <br />
              &bull; Information about the device used to visit our website; and
              <br />
              &bull; IP addresses.
              <br />
              We use technology called cookies when you visit our site. Cookies
              are small pieces of information stored on your hard drive or in
              memory. They can record information about your visit to the site,
              allowing it to remember you the next time you visit and provide a
              more meaningful experience.
            </span>
          </p>
          <p>
            <span>
              One of the reasons for using cookies is to offer you increased
              security. The cookies we send to your computer cannot read your
              hard drive, obtain any information from your browser or command
              your computer to perform any action. They are designed so that
              they cannot be sent to another site, or be retrieved by any
              non-Parinity site.
            </span>
          </p>
          <p>
            <span>
              We won&rsquo;t ask you to supply personal information publicly
              over facebook, Twitter, or any other social media platform that we
              use. Sometimes we may invite you to send your details to us via
              private messaging, for example, to answer a question. You may also
              be invited to share your personal information through secure
              channels to participate in other activities, such as competitions.
            </span>
          </p>
          <h4>
            <span>Access to and correction of personal information</span>
          </h4>
          <p>
            <span>
              You can request access to the personal information we hold about
              you. You can also ask for corrections to be made. To do so, please
              contact us.
            </span>
          </p>
          <p>
            <span>
              There is no fee for requesting that your personal information is
              corrected or for us to make corrections. In processing your
              request for access to your personal information, a reasonable cost
              may be charged. This charge covers such things as locating the
              information and supplying it to you.
            </span>
          </p>
          <p>
            <span>
              There are some circumstances in which we are not required to give
              you access to your personal information.
            </span>
          </p>
          <p>
            <span>
              If we refuse to give you access to or to correct your personal
              information we will give you a notice explaining our reasons
              except where it would be unreasonable to do so.
            </span>
          </p>
          <p>
            <span>
              If we refuse your request to correct your personal information,
              you also have the right to request that a statement be associated
              with your personal information noting that you disagree with its
              accuracy.
            </span>
          </p>
          <p>
            <span>
              If we refuse your request to access or correct your personal
              information, we will also provide you with information on how you
              can complain about the refusal.
            </span>
          </p>
          <h4>
            <span>
              Resolving your privacy concerns and complaints &ndash; your rights
            </span>
          </h4>
          <p>
            <span>
              If you are concerned about how your personal information is being
              handled or if you have a complaint about a breach by us of the
              Australian Privacy Principles, please contact us.
            </span>
          </p>
          <p>
            <span>
              We will acknowledge your complaint as soon as we can after receipt
              of your complaint. We will let you know if we need any further
              information from you to resolve your complaint.
            </span>
          </p>
          <p>
            <span>
              We aim to resolve complaints as quickly as possible. We strive to
              resolve complaints within five business days but some complaints
              take longer to resolve. If your complaint is taking longer, we
              will let you know what is happening and a date by which you can
              reasonably expect a response.
            </span>
          </p>
          <p>
            <span>
              If you are unhappy with our response, there are other bodies you
              can go to.
            </span>
          </p>
          <p>
            <span>
              The Financial Ombudsman Service (FOS) can consider most privacy
              complaints involving providers of financial services.
            </span>
          </p>
          <h4>
            <span>FOS can be contacted at:</span>
          </h4>
          <p>
            <span>
              Financial Ombudsman Service
              <br />
              GPO Box 3<br />
              Melbourne VIC 3001
              <br />
              Phone: 1300 78 08 08
              <br />
              www.fos.org.au
            </span>
          </p>
          <p>
            <span>
              Under the Privacy Act you may complain to the Office of the
              Australian Information Commissioner about the way we handle your
              personal information.
            </span>
          </p>
          <p>
            <span>
              The Commissioner can be contacted at:
              <br />
              GPO Box 5218
              <br />
              Sydney NSW 2001
              <br />
              Phone:&nbsp;
            </span>
            <span>1300 363 992</span>
            <span>
              <br />
              Email:&nbsp;
            </span>
            <span>
              <a href="mailto:enquiries@oaic.gov.au">enquiries@oaic.gov.au</a>
            </span>
            <span>
              <br />
            </span>
            <span>
              <a href="https://www.google.com/url?q=http://www.oaic.gov.au/&amp;sa=D&amp;ust=1591414732557000">
                www.oaic.gov.au
              </a>
            </span>
          </p>
          <h4>
            <span>Contact us</span>
          </h4>
          <p>
            <span>
              You can contact us by:
              <br />
              &bull; calling&nbsp;
            </span>
            <span>07 3114</span>
            <span>
              &nbsp;2500
              <br />
              &bull; emailing&nbsp;
            </span>
            <span>
              <a href="mailto:info@parinity.com.au">info@parinity.com.au</a>
            </span>
            <span>
              <br />
              &bull; visiting&nbsp;
            </span>
            <span>
              <a href="https://www.google.com/url?q=http://www.parinity.com.au&amp;sa=D&amp;ust=1591414732558000">
                www.parinity.com.au
              </a>
            </span>
            <span>
              <br />
              &bull; writing to us at: Parinity, PO Box 65, Camp Hill, QLD, 4152
            </span>
          </p>
          <p>
            <span>
              Our Privacy Officer can also be contacted in relation to privacy
              concerns using the same contact details.
            </span>
          </p>
          <h4>
            <span>Changes to the Privacy Policy</span>
          </h4>
          <p>
            <span>
              We may change the way we handle personal information from time to
              time for any reason. If we do so, we will update this Privacy
              Policy. An up-to-date version is available on www.parinity.com.au
              or by calling us on&nbsp;07 3114 2500.
            </span>
          </p>
          <h4>
            <span>Meaning of words</span>
          </h4>
          <p>
            <span>
              We, us or our means:
              <br />
              &bull; Parinity Pty Ltd, trading as Parinity, ABN 39 151 423 317.
              <br />
              The Parinity Group and &lsquo;our licensee&rsquo; means The
              Parinity Group Pty Ltd (ABN 86 169 485 885; AFSL 458943) and its
              related bodies corporate.
            </span>
          </p>
        </div>
      </FAQs>
    </FAQContainer>
  </Layout>
)
